import React from "react";
import { ImagePage } from "../../components/imageGallery/ImagePage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import * as images from '../../config/images';

const photos = images[2];

export const BenAndBenImageGallery2 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/ben-and-ben"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Benjamin - Ben</h1>

                </Link>
            </nav>
            <h2 className="pageNavigation">Science | Ciencia</h2>
            <ImagePage photos={photos} />
        </React.Fragment>
    );
};
