import React from "react";
import { ImagePage } from "../../components/imageGallery/ImagePage";
import * as images from '../../config/images';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const photos = images[17];

export const TomAndScottImageGallery1 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/tom-and-scott"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Tom - Scott</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Art | Arte</h2>

            <ImagePage photos={photos} />

        </React.Fragment>
    );
};
