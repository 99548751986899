import React from 'react';
import './HomeLogo.css';

export const HomeLogo = () => {
    return (
            <div className="logo">
                <img src="images/pages/background/logo_wht.png" alt="logo" />
            </div>
    )
}
