import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HeatherAndTaylorPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/heather-and-taylor"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Heather - Taylor</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/chuckwallaTwoTest.jpg"}
                />
                <div className="whoContainer">
                    <div className="personOne">

                        <img src="/images/pages/who/handt/heatherGreen.jpg" alt="heather and taylor" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                         <span className="boldText">   Heather Green  </span> is an artist whose projects and installations examine historical and
ecological narratives of the Northern Gulf of California and Sonoran Desert, specifically
Cholla Bay. Through the collaborative nature of her art she has allied with a diverse
range of individuals including scientists, poets and ﬁshermen. Her work has been shown
in México, Uruguay, and in museums and galleries both regionally and across the United
States. A native of Tucson, Heather currently works in Tempe as Assistant Professor of
Book Arts at Arizona State University.

                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">     Heather Green </span>  es una artista cuyos proyectos e instalaciones examinan las narrativas
históricas y ecológicas del norte del Golfo de California y del desierto de Sonora,
específicamente de la Bahía de Cholla. A través de la naturaleza colaborativa de su arte,
se ha aliado con una amplia gama de individuos que incluye los científicos, poetas y
pescadores. Su trabajo se ha mostrado en México, Uruguay, y en museos y galerías tanto
a nivel regional como nacional de los Estados Unidos. Heather, nativa de Tucson,
actualmente trabaja en Tempe como Profesora Asistente de Book Arts en la Universidad
Estatal de Arizona.
                            </p>
                    </div>
                    <div className="personTwo">

                        <img src="/images/pages/who/handt/taylorEdwardsProfile08.jpg" alt="heather and taylor" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">    Taylor Edwards </span>  is a herpetologist, evolutionary biologist and conservation geneticist at
the University of Arizona Genetics Core in Tucson. Taylor is a leading expert on tortoise
genetics and his Ph.D. work led to the description of a new species of tortoise and the
preservation of crucial habitat in Mexico. Taylor serves as an Advisory Board member
of the Turtle Conservancy, Scientific Advisor for the Turner Foundation (Bolson
Tortoise Captive Breeding and Repatriation Project), and is a NatGeo Expert with
National Geographic Expeditions.

                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">    Taylor Edwards </span>  es herpetólogo, biólogo de evolución y genetista de conservación en la
Universidad de Arizona Genetics Core en Tucson. Taylor es un experto líder en la
genética de tortugas y las investigaciones de su Ph.D. resultó en la descripción de una
nueva especie de tortuga y la preservación de hábitats cruciales en México. Taylor se
desempeña como miembro de la Junta Asesora de Turtle Conservancy, Asesor
Científico de la Fundación Turner (Proyecto de cría en cautividad y repatriación de la
tortuga Bolson) y Experto de NatGeo en National Geographic Expeditions.
                            </p>
                    </div>
                </div>
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
