import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const KathleenAndMichaelPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/kathleen-and-michael"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Kathleen - Michael</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/photogramSampleThreeTest.jpg"}
                />
                <div className="whoContainer">
                    <div className="personOne">
                        <img src="/images/pages/who/kandm/kathleenVelo.jpg" alt="kathleen and michael" />

                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">   Kathleen Velo</span> is a photographic artist and fine art studio artist who is interested in the
interaction of humans with the natural world. As a process-driven artist, Kathleen
created a technique to maximize her interaction with the natural alchemy of light,
chemistry, and space, using camera-less, pinhole and plastic camera techniques to
capture her imagery. Her work has been exhibited in galleries and museums nationally
and internationally, including the Palace of the Governors Museum in Santa Fe, Tucson
Museum of Art, Southeastern Museum of Photography, and The Center for Fine Art
Photography. Most recently, her photographic work has focused on water quality issues
in the Southwest.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">     Kathleen Velo </span>es una artista fotográfica y artista de bellas artes que tiene interés en la
interacción de los humanos con el mundo natural. Como artista impulsada por el
proceso, Kathleen creó una técnica para maximizar su interacción con la alquimia
natural de la luz, la química y el espacio, utilizando técnicas sin cámara, de “pinhole”, y
de cámara de plástico para capturar sus imágenes. Su trabajo ha sido exhibido en
galerías y museos a nivel nacional e internacional, incluyendo el Museo del Palacio de los
Gobernadores en Santa Fe, el Museo de Arte de Tucson, el Museo de Fotografía del
Sureste y el Centro de Fotografía de Bellas Artes. Más recientemente, su trabajo
fotográfico se ha centrado en los problemas de calidad del agua en el suroeste.
                            </p>
                    </div>
                    <div className="personTwo">
                        <img src="/images/pages/who/kandm/michaelBogan.jpg" alt="kathleen and michael" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">   Michael Bogan</span> is an aquatic ecologist in the School of Natural Resources and the
Environment at the University of Arizona in Tucson. His research focuses on how
disturbance and dispersal processes shape local and regional biodiversity patterns in
aquatic and riparian ecosystems, including the impacts of drought on arid-land streams.
Most of his work focuses on invertebrates in Madrean Sky Island streams, Sonoran
Desert oases, and coastal drainages of California.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">   Michael Bogan</span> es ecólogo acuático en la Escuela de Recursos Naturales y Medio
Ambiente de la Universidad de Arizona en Tucson. Su investigación se centra en cómo los
procesos de perturbación y dispersión moldean los patrones de biodiversidad locales y
regionales en los ecosistemas acuáticos y ribereños, incluyendo los impactos de la sequía
en las corrientes de tierras áridas. La mayor parte de su trabajo se centra en los
invertebrados en los arroyos Madrean Sky Island, los oasis del Desierto Sonorense y los
drenajes costeros de California.
                            </p>
                    </div>
                </div>
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
