import React from "react";
import { Link } from "react-router-dom";
import './IconCard.css'
import {
    UserFriendsIcon,
    PaintBrushIcon,
    MicroscopeIcon,
    LightBulbIcon,
    GlobeIcon
} from "../../components/UI/Icons";


const style = {
    height: "100px",
    width: "140px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "2em",
    'marginLeft': '8px',
    border: "1px solid black"
};

export const IconCard = ({ link, color, size, icon, title }) => {
    let IconComponent;
    switch (icon) {
        case "globe":
            IconComponent = GlobeIcon;
            break;
        case "light-bulb":
            IconComponent = LightBulbIcon;
            break;
        case "microscope":
            IconComponent = MicroscopeIcon;
            break;
        case "paint-brush":
            IconComponent = PaintBrushIcon;
            break;
        case "user-friends":
            IconComponent = UserFriendsIcon;
            break;
        default:
            throw new Error(`Wrong icon prop specified: ${icon}`);
    }

    const linkStyle = {
        textDecoration: "none",
        color
    }

    return (
        <Link to={link} style={linkStyle}>
            <div className="icons" style={style}>
                <h2 className="iconTitle">{title}</h2>
                <IconComponent color={color} size={size} />
            </div>
        </Link>
    );
};
