import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './tomAndScott.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TomAndScottImageGallery3 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/tom-and-scott"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Tom - Scott</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>

            <div className="whyContainer">
                <div className="whyDescriptionTomAndScottEnglish">
                    <h3><strong>Paleogeographic Rendering of the Ancient Gulf of California</strong></h3>
                    <br />
                    <p>
                        The Sonoran Desert is host to a dynamic and evolving landscape. Plate tectonics help form features such
    as mountains that seem to be permanent parts of the environment, when in fact they are undergoing a
    pattern of constant change. Over millions of years, mountains can be uplifted and eroded and in the case
    of our region, seas are born.
                    </p>
                    <br />
                    <p>
                        Natural science, especially geology, shows us that the Sonoran Desert landscape came about as a result
    of a long chain of events, stretching millions of years into the past. These tectonic motions tilted mountain
    ranges, produce extensive valleys, and formed a long narrow trough into which the Gulf of California
    gradually flooded. These forces created the Sonoran Desert landscape we know today, which serves as
    the canvas for biologic, ecologic, and anthropologic patterns. The Gulf of California seaway is host to the
    richest estuary on the planet, with marine diversity comparable to the rainforest and a magical
    paradoxical intersection of a teaming ocean life and a harsh desert.
                    </p>
                    <br />
                    <p>To create an approximation of topographic features that existed millions of years ago, Scott supplied Tom
with paleo-tectonic maps. These maps summarized key geologic aspects of the Gulf of California rift,
including active and past faults, locations of ancient marine fossils, and rock formations that used to
stretch across the Gulf of California before rifting began. These artistic maps, created manually in Adobe
Photoshop, are glimpses into the past that created the Sonoran Desert of today.
                </p>
                <br/>
                <p>
                Plate tectonic reconstructions used here are the result of collaborative research between geologists with the U.S. Geological Survey, University of California Davis, Northern Arizona University, and the University of Oregon. For more details, please refer to:
                </p>
                <br/>
                <p>
                Bennett, S.E.K., Skinner, L.A., Darin, M.H., Umhoefer, P.J., Oskin, M.E., and Dorsey, R.J. (2013), New Constraints on Baja California-North America Relative Plate Motion Since 11 Ma,  Abstract T14C-02 presented at 2013 Fall Meeting, AGU, San Francisco, CA, 9-13 Dec.

                </p>
                </div>
                <div className="whyDescriptionTomAndScottSpanish">
                    <h3><strong>Representación paleogeográfica del antiguo Golfo de California</strong></h3>
                    <br />
                    <p>
                        El Desierto Sonorense es un paisaje dinámico en estado de evolución. Las placas tectónicas
    ayudan a formar distintivos como montañas que parecen ser parte permanente del entorno,
    cuando en realidad experimentan un patrón de cambio constante. Durante millones de años,
    las montañas crecen y se erosionan y, en el caso de nuestra región, nacen mares.
                    </p>
                    <br />
                    <p>
                        Las ciencias naturales, especialmente la geología, nos muestran que el paisaje del Desierto
    Sonorense surgió como resultado de una larga cadena de eventos que se prolongaron durante
    millones de años. Estos movimientos tectónicos inclinaron las cadenas montañosas, produjeron
    extensos valles y formaron el largo y estrecho canal donde el Golfo de California se fue
    inundando gradualmente. Estas fuerzas crearon el paisaje del Desierto Sonorense como lo
    conocemos hoy, el cual sirve como lienzo de patrones biológicos, ecológicos y antropológicos.
    La vía marítima del Golfo de California cuenta con el estuario más rico del planeta, con una
    diversidad marina comparable a la de los bosques lluviosos y ofrece una intersección
    paradójica y mágica donde convergen una prolífica vida marina y el severo desierto.
                    </p>
                    <br />
                    <p>Para crear una aproximación a las características topográficas que existieron hace millones de
años, Scott proporcionó a Tom mapas paleo-tectónicos. Estos mapas resumen aspectos
geológicos clave de la grieta del Golfo de California e incluyen las fallas activas y pasadas, así
como la ubicación de antiguos fósiles marinos y las formaciones rocosas que solían extenderse
por el Golfo de California antes de que comenzara a fisurarse. Estos artísticos mapas, creados
en Adobe Photoshop, nos ofrecen una mirada al pasado que creó el Desierto Sonorense de
hoy.</p>


                </div>
            </div>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/NewTomScottBackground1.jpg"}
                />
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
