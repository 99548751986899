import React from "react";
import "./HomeGridCard.css";
import { Link } from "react-router-dom";


// function random(){

// }
// export const HomeGridCard = ({ imageUrl, link, title }) => {
//     return (
//         <Link to={link} className={"grid-item-link"}>
//             <div className="grid-card">
//                 <img src={imageUrl} alt='img'/>
//                 <h1 className="landingPageTitle">{title}</h1>
//             </div>
//         </Link>
//     );
// };
// const styles = {opacity: '.6'}

class HomeGridCard extends React.Component {
    // constructor(){
    //     super()
    //     this.state ={
    //         opacity: '.5'
    //     }
    // }
    // componentDidMount(){
    //     setInterval(() => {
    //         this.setState({
    //             opacity: '.9'
    //         })
    //     }, 3000); 
    //     setInterval(()=>{
    //         this.setState({
    //             opacity: '.4'
    //         })  
    //     },2000)  
      
    // }

    render() {

        const { imageUrl, link, title } = this.props
        return (
            <Link to={link}  className={"grid-item-link"}>
                <div  className="grid-card">
                    <img  src={imageUrl} alt='img' />
                    <h1 className="landingPageTitle">{title}</h1>
                </div>
            </Link>
        )
    }
}
export default HomeGridCard