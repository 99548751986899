import React, { Component } from "react";
import { AppRouter } from "./router/AppRouter";
import "./style/reset.css";
import "./style/global.css";
/*
 * Font awesome react package
 */
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faGlobeAmericas,
    faBrain,
    faMicroscope,
    faPaintBrush,
    faUserFriends,
    faArrowLeft
} from "@fortawesome/free-solid-svg-icons";

library.add(faUserFriends);
library.add(faPaintBrush);
library.add(faGlobeAmericas);
library.add(faMicroscope);
library.add(faBrain);
library.add(faArrowLeft);

class App extends Component {
    render() {
        return <AppRouter />;
    }
}

export default App;
