import React from "react";
import { ImagePage } from "../../components/imageGallery/ImagePage";
import { Link } from "react-router-dom";
import * as images from '../../config/images';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const photos = images[3];

export const BenAndBenImageGallery4 = () => {
    return (
        <React.Fragment>
           <nav>
                <Link to={"/ben-and-ben"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Benjamin - Ben</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Place | Lugar</h2>

            <ImagePage photos={photos} />
        </React.Fragment>
    );
};
