import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import '../pages.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const BenAndBenPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/ben-and-ben"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Benjamin - Ben</h1>
                </Link>

            </nav>

            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={
                        "/images/pages/background/benJmodeltreeProcessedBW.jpg"
                    }
                />
                <div className="whoContainer">
                    <div className="personOne">
                        <img src="/images/pages/who/bandb/benJ.jpg" alt="benandben" />
                    </div>
                    <div className="bioText">

                        <br />
                        <p>
                        <span className="boldText">Benjamin M. Johnson</span> is a visual artist and curator, whose work reflects his love of the
natural world while weaving together resonant themes of history, science, spirituality,
and culture. Benjamin works in traditional and new media and exhibits nationally.
Originally from New Jersey, he studied at the Pennsylvania Academy of the Fine Arts and
is a prior Curator of Exhibitions with Tohono Chul Park. He is currently based in Tucson,
Arizona.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                             <span className="boldText"> Benjamin M. Johnson</span> es un artista visual y curador, cuya obra refleja su amor por el
   mundo natural mientras que teje temas resonantes de la historia, la ciencia, la
   espiritualidad y la cultura. Benjamín trabaja en medios tradicionales y nuevos y expone a
   nivel nacional. Originario de Nueva Jersey, estudió en la Academia de Bellas Artes de
   Pensilvania y fue curador de exposiciones con Tohono Chul Park. Actualmente reside en
Tucson, Arizona.

                        </p>
                    </div>

                    <div className="personTwo">
                        <img src="/images/pages/who/bandb/benWilder.jpg" alt="benandben" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText"> Benjamin T. Wilder </span>is a desert ecologist and botanist. Director of the Desert Laboratory
on Tumamoc Hill at the University of Arizona, and Director and Co-Founder of the
Next Generation Sonoran Desert Researchers (N-Gen), Ben utilizes multiple approaches
to connect science to conservation. As a biogeographer, Ben’s research spans time and
space in arid regions to establish baselines that offer a better understanding of modern
biodiversity.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">   Benjamin T. Wilder</span> es un ecologista de desierto y un botánico. Director del Laboratorio
del Desierto en Tumamoc Hill de la Universidad de Arizona y Director y Co-fundador de la
Nueva Generación de Investigadores del Desierto Sonorense (N-Gen), Ben utiliza
múltiples enfoques para conectar la ciencia con la conservación. Como biogeógrafo, sus
investigaciones abarca el tiempo y el espacio en las regiones áridas para establecer
bases de referencia que ofrezcan una mejor comprensión de la biodiversidad moderna.
                            </p>
                    </div>

                </div>

                <NavBarLogo />

            </PageContainer>
        </React.Fragment>
    );
};
