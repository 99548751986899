import React from "react";
// import { Background } from "../../components/UI/Background";
import { HomeGrid } from "../../components/home/HomeGrid";
import { HomeLogo } from "../../components/home/HomeLogo";

const homeGridItems = [
    {
        imageUrl: "/images/pages/background/newLandingPageImages/benDetailProcessed.jpg",
        title: "Benjamin - Ben",
        link: "/ben-and-ben"
    },
    {
        imageUrl: "/images/pages/background/newLandingPageImages/chipDetailProcessed.jpg",
        title: "Chip - Mark",
        link: "/chip-and-mark"
    },
    {
        imageUrl: "/images/pages/background/newLandingPageImages/heatherDetailProcessed.jpg",
        title: "Heather - Taylor",
        link: "/heather-and-taylor"
    },
    {
        imageUrl: "/images/pages/background/newLandingPageImages/kathleenDetailProcessed.jpg",
        title: "Kathleen - Michael",
        link: "/kathleen-and-michael"
    },
    {
        imageUrl: "/images/pages/background/newLandingPageImages/mariaDetailProcessed.jpg",
        title: "Maria - Eric",
        link: "/maria-and-eric"
    },
    {
        imageUrl: "/images/pages/background/newLandingPageImages/scottDetailProcessed.jpg",
        title: "Tom - Scott",
        link: "/tom-and-scott"
    }
];

export const Home = () => {
    return (
        <React.Fragment>
            {/* <Background
                imgUrl={"/images/pages/background/hedgcock_150120_0003_1.jpg"}
            /> */}
            <HomeGrid homeGridItems={homeGridItems} />
            <HomeLogo />
        </React.Fragment>
    );
};
