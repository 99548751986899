import React from 'react'
import { Background } from '../../components/UI/Background';
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { ProjectContainer } from "../../components/project/ProjectContainer";
import { Title } from "../../components/project/Title";
import { IconGrid } from "../../components/project/IconGrid";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const projectItems = [
    {
        icon: "user-friends",
        title: "People | Gente",
        link: "/heather-and-taylor/people"
    },
    {
        icon: "light-bulb",
        title: "Concept | Lógica",
        link: "/heather-and-taylor/image-gallery-3"
    },
    {
        icon: "microscope",
        title: "Science | Ciencia",
        link: "/heather-and-taylor/image-gallery-2"
    },
    {
        icon: "paint-brush",
        title: "Art | Arte",
        link: "/heather-and-taylor/image-gallery-1"
    },
    {
        icon: "globe",
        title: "Place | Lugar",
        link: "/heather-and-taylor/image-gallery-4"
    }
];

const projectStyle = {
    color: "black",
    size: "4em"
};
export const HeatherAndTaylor = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/></h1>
                </Link>
            </nav>
            <PageContainer>
                <Background imgUrl={"/images/pages/background/chuckwallaTwoTest.jpg"} />
                <NavBarLogo />
                <ProjectContainer>
                    <Title color={"white"} title={"Heather - Taylor"} />
                    <IconGrid
                        projectItems={projectItems}
                        projectStyle={projectStyle}
                    />
                </ProjectContainer>
            </PageContainer>
        </React.Fragment>
    );
};
