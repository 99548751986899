import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { Home } from "../pages/home/Home";
import { BenAndBen } from "../pages/benAndBen/BenAndBen";
import { ChipAndMark } from "../pages/chipAndMark/ChipAndMark";
import { HeatherAndTaylor } from "../pages/heatherAndTaylor/HeatherAndTaylor";
import { KathleenAndMichael } from "../pages/kathleenAndMichael/KathleenAndMichael";
import { MariaAndEric } from "../pages/mariaAndEric/MariaAndEric";
import { TomAndScott } from "../pages/tomAndScott/TomAndScott";
import { BenAndBenPeople } from "../pages/benAndBen/BenAndBenPeople";
import { BenAndBenImageGallery1 } from "../pages/benAndBen/BenAndBenImageGallery1";
import { BenAndBenImageGallery2 } from "../pages/benAndBen/BenAndBenImageGallery2";
import { BenAndBenImageGallery3 } from "../pages/benAndBen/BenAndBenImageGallery3";
import { BenAndBenImageGallery4 } from "../pages/benAndBen/BenAndBenImageGallery4";
import { ChipAndMarkPeople } from "../pages/chipAndMark/ChipAndMarkPeople";
import { ChipAndMarkImageGallery1 } from "../pages/chipAndMark/ChipAndMarkImageGallery1";
import { ChipAndMarkImageGallery2 } from "../pages/chipAndMark/ChipAndMarkImageGallery2";
import { ChipAndMarkImageGallery3 } from "../pages/chipAndMark/ChipAndMarkImageGallery3";
import { ChipAndMarkImageGallery4 } from "../pages/chipAndMark/ChipAndMarkImageGallery4";
import { HeatherAndTaylorPeople } from "../pages/heatherAndTaylor/HeatherAndTaylorPeople";
import { HeatherAndTaylorImageGallery1 } from "../pages/heatherAndTaylor/HeatherAndTaylorImageGallery1";
import { HeatherAndTaylorImageGallery2 } from "../pages/heatherAndTaylor/HeatherAndTaylorImageGallery2";
import { HeatherAndTaylorImageGallery3 } from "../pages/heatherAndTaylor/HeatherAndTaylorImageGallery3";
import { HeatherAndTaylorImageGallery4 } from "../pages/heatherAndTaylor/HeatherAndTaylorImageGallery4";
import { KathleenAndMichaelPeople } from "../pages/kathleenAndMichael/KathleenAndMichaelPeople";
import { KathleenAndMichaelImageGallery1 } from "../pages/kathleenAndMichael/KathleenAndMichaelImageGallery1";
import { KathleenAndMichaelImageGallery2 } from "../pages/kathleenAndMichael/KathleenAndMichaelImageGallery2";
import { KathleenAndMichaelImageGallery3 } from "../pages/kathleenAndMichael/KathleenAndMichaelImageGallery3";
import { KathleenAndMichaelImageGallery4 } from "../pages/kathleenAndMichael/KathleenAndMichaelImageGallery4";
import { MariaAndEricImageGallery1 } from "../pages/mariaAndEric/MariaAndEricImageGallery1";
import { MariaAndEricPeople } from "../pages/mariaAndEric/MariaAndEricPeople";
import { MariaAndEricImageGallery2 } from "../pages/mariaAndEric/MariaAndEricImageGallery2";
import { MariaAndEricImageGallery3 } from "../pages/mariaAndEric/MariaAndEricImageGallery3";
import { MariaAndEricImageGallery4 } from "../pages/mariaAndEric/MariaAndEricImageGallery4";
import { TomAndScottPeople } from "../pages/tomAndScott/TomAndScottPeople";
import { TomAndScottImageGallery1 } from "../pages/tomAndScott/TomAndScottImageGallery1";
import { TomAndScottImageGallery2 } from "../pages/tomAndScott/TomAndScottImageGallery2";
import { TomAndScottImageGallery3 } from "../pages/tomAndScott/TomAndScottImageGallery3";
import { TomAndScottImageGallery4 } from "../pages/tomAndScott/TomAndScottImageGallery4";

export const AppRouter = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={Home} />
                <Route exact path="/ben-and-ben" component={BenAndBen} />
                <Route
                    exact
                    path="/ben-and-ben/people"
                    component={BenAndBenPeople}
                />
                <Route
                    exact
                    path="/ben-and-ben/image-gallery-1"
                    component={BenAndBenImageGallery1}
                />
                <Route
                    exact
                    path="/ben-and-ben/image-gallery-2"
                    component={BenAndBenImageGallery2}
                />
                <Route
                    exact
                    path="/ben-and-ben/image-gallery-3"
                    component={BenAndBenImageGallery3}
                />
                <Route
                    exact
                    path="/ben-and-ben/image-gallery-4"
                    component={BenAndBenImageGallery4}
                />
                <Route exact path="/chip-and-mark" component={ChipAndMark} />
                <Route
                    exact
                    path="/chip-and-mark/people"
                    component={ChipAndMarkPeople}
                />
                <Route
                    exact
                    path="/chip-and-mark/image-gallery-1"
                    component={ChipAndMarkImageGallery1}
                />
                <Route
                    exact
                    path="/chip-and-mark/image-gallery-2"
                    component={ChipAndMarkImageGallery2}
                />
                <Route
                    exact
                    path="/chip-and-mark/image-gallery-3"
                    component={ChipAndMarkImageGallery3}
                />
                <Route
                    exact
                    path="/chip-and-mark/image-gallery-4"
                    component={ChipAndMarkImageGallery4}
                />
                <Route
                    exact
                    path="/heather-and-taylor"
                    component={HeatherAndTaylor}
                />
                <Route
                    exact
                    path="/heather-and-taylor/people"
                    component={HeatherAndTaylorPeople}
                />
                <Route
                    exact
                    path="/heather-and-taylor/image-gallery-1"
                    component={HeatherAndTaylorImageGallery1}
                />
                <Route
                    exact
                    path="/heather-and-taylor/image-gallery-2"
                    component={HeatherAndTaylorImageGallery2}
                />
                <Route
                    exact
                    path="/heather-and-taylor/image-gallery-3"
                    component={HeatherAndTaylorImageGallery3}
                />
                <Route
                    exact
                    path="/heather-and-taylor/image-gallery-4"
                    component={HeatherAndTaylorImageGallery4}
                />
                <Route
                    exact
                    path="/kathleen-and-michael"
                    component={KathleenAndMichael}
                />
                  <Route
                    exact
                    path="/kathleen-and-michael/people"
                    component={KathleenAndMichaelPeople}
                />
                 <Route
                    exact
                    path="/kathleen-and-michael/image-gallery-1"
                    component={KathleenAndMichaelImageGallery1}
                />
                <Route
                    exact
                    path="/kathleen-and-michael/image-gallery-2"
                    component={KathleenAndMichaelImageGallery2}
                />
                <Route
                    exact
                    path="/kathleen-and-michael/image-gallery-3"
                    component={KathleenAndMichaelImageGallery3}
                />
                <Route
                    exact
                    path="/kathleen-and-michael/image-gallery-4"
                    component={KathleenAndMichaelImageGallery4}
                />
                <Route exact path="/maria-and-eric" component={MariaAndEric} />
                <Route
                    exact
                    path="/maria-and-eric/people"
                    component={MariaAndEricPeople}
                />
                <Route
                    exact
                    path="/maria-and-eric/image-gallery-1"
                    component={MariaAndEricImageGallery1}
                />
                <Route
                    exact
                    path="/maria-and-eric/image-gallery-2"
                    component={MariaAndEricImageGallery2}
                />
                <Route
                    exact
                    path="/maria-and-eric/image-gallery-3"
                    component={MariaAndEricImageGallery3}
                />
                <Route
                    exact
                    path="/maria-and-eric/image-gallery-4"
                    component={MariaAndEricImageGallery4}
                />
                <Route exact path="/tom-and-scott" component={TomAndScott} />
                <Route
                    exact
                    path="/tom-and-scott/people"
                    component={TomAndScottPeople}
                />
                <Route
                    exact
                    path="/tom-and-scott/image-gallery-1"
                    component={TomAndScottImageGallery1}
                />
                <Route
                    exact
                    path="/tom-and-scott/image-gallery-2"
                    component={TomAndScottImageGallery2}
                />
                <Route
                    exact
                    path="/tom-and-scott/image-gallery-3"
                    component={TomAndScottImageGallery3}
                />
                <Route
                    exact
                    path="/tom-and-scott/image-gallery-4"
                    component={TomAndScottImageGallery4}
                />
            </Switch>
        </Router>
    );
};
