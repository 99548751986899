import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MariaAndEricPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/maria-and-eric"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Maria - Eric</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/NewMariaEricBackground.jpg"}
                />
                <div className="whoContainer">
                    <div className="personOne">
                        <img src="/images/pages/who/mande/johnsonMaria.jpg" alt="maria and eric" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">     Maria R. Johnson</span> is a visual artist and marine scientist. Her areas of research include
fisheries, art-science, and coupled human-ecological systems. For many years, she
worked as a Research and Conservation Fellow at the Prescott College Kino Bay Center
in Bahía Kino, Sonora where she studied shrimp trawler bycatch. Her artistic works
explore ecology and strive to connect people to place through ink, watercolor, and
photography. She is currently a Master of Science candidate in Marine Resource
Management at Oregon State University.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">     Maria R. Johnson</span> es una artista visual y científica marina. Sus áreas de investigación
incluyen la pesca, el arte-ciencia y los sistemas humanos-ecológicos. Durante muchos
años, trabajó como Becaria de Investigación y Conservación en el Prescott College Kino
Bay Center de Bahía Kino, Sonora, donde estudió la captura incidental de camarones. Sus
obras artísticas exploran la ecología e intentan conectar a las personas al sentido de lugar a través de tinta, acuarela y fotografía. Actualmente es candidata de la Maestría
de Ciencias en el Manejo de Recursos Marinos en la Universidad Estatal de Oregón.
                            </p>
                    </div>
                    <div className="personTwo">
                        <img src="/images/pages/who/mande/magranePhoto.jpg" alt="maria and eric" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">     Eric Magrane</span> is a poet and geographer. He is an assistant professor of geography at
New Mexico State University in Las Cruces. The co-editor of The Sonoran Desert: A
Literary Field Guide, he has also served as poet in residence at the Arizona-Sonora
Desert Museum in Tucson. His research areas include creative geographies, the
geographies of art and science, science communication, environmental humanities, and
narratives of climate change and the Anthropocene.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">     Eric Magrane </span>es poeta y geógrafo. Eric es un profesor asistente de geografía de la
Universidad Estatal de Nuevo México en Las Cruces. Co-editor de The Sonoran Desert: A
Literary Field Guide, también se ha desempeñado como poeta residente del Arizona-
Sonora Desert Museum en Tucson. Sus áreas de investigación incluyen geografías
creativas, geografías de arte y ciencia, comunicación científica, humanidades
ambientales y narrativas del cambio climático y el Antropoceno.
                            </p>
                    </div>
                </div>
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
