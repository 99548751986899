import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const MicroscopeIcon = ({ color, size }) => {
    const style = {
        color,
        fontSize: size
    };

    return <FontAwesomeIcon icon="microscope" style={style} />;
};
