import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './benAndBen.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const BenAndBenImageGallery3 = () => {
    return (
        <React.Fragment>

            <nav>
                <Link to={"/ben-and-ben"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Benjamin - Ben</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>

            <PageContainer>


                <Background
                    imgUrl={
                        "/images/pages/background/benJmodeltreeProcessedBW.jpg"
                    }
                />

                <div className="whyContainer">

                    <div className="whyDescriptionBenAndBen">

                        <h3><strong>Hidden Water: Pozos of the Gran Desierto</strong></h3>
                        <br />
                        <p>
                            The Gran Desierto de Altar of the Sonoran Desert is the largest extent of sand dunes in North
    America. Once the interior of the Grand Canyon, these grains of sand excavated by the
    Colorado River and deposited at its delta dominate the landscape for hundreds of kilometers.
    Miraculously, an array of freshwater springs – pozos – punctuate the sand and salt flats, the
    only fresh water in any direction. For millennia these enigmatic oases have been a confluence
    point for culture and life, the destination point for the Tohono O’odham salt pilgrimage. Today,
    foundational questions remain: What is the age and origin of this freshwater? How have these
    wetlands changed through time? What is their fate?
                    </p>
                        <br />
                        <p>
                            This transdisciplinary collaboration between a botanist, hydrologist, and artist has sought to
    answer these questions. Through the avenues of scientific research and artistic inquiry, the
    team has uncovered new truths about the history of these springs. Rather than recent local
    recharge from winter rains, the pozos are fed by ancient Colorado River water over 10,000
    years old. This collection of views of the pozos sheds light on their complex, interwoven history
    and gestures towards a future of preservation for this life giving water.
                    </p>

                    </div>
                    <div className="whyDescriptionBenAndBenSpanish">
                        <h3><strong>Agua escondida: pozos del Gran Desierto</strong></h3>
                        <br />
                        <p>
                            El Gran Desierto de Altar del Desierto Sonorense es la mayor extensión de dunas de arena en
    Norteamérica. Una vez en el interior del Gran Cañón, los granos de arena, excavados por el
    Río Colorado y depositados en su delta, dominan el paisaje por cientos de kilómetros.
    Milagrosamente, una serie de manantiales de agua dulce – pozos – enfatizan el arena y las
    salinas, pues son el única agua dulce en cualquier dirección. Durante milenios, estos
    enigmáticos oasis han sido un punto de confluencia para la cultura y la vida, el punto de destino
    para la peregrinación de sal de Tohono O’odham. Hoy en día las preguntas fundamentales
    permanecen: ¿Cuál es la edad y el origen de esta agua dulce? ¿Cómo han cambiado estos
    humedales a través del tiempo? ¿Cuál es su destino?
                    </p>
                        <br />
                        <p>
                            Esta colaboración transdisciplinaria entre un botánico, un hidrólogo y un artista, ha intentado
    responder estas preguntas. A través de la investigación científica y artística, el equipo ha
    descubierto nuevas verdades sobre la historia de estos manantiales. En lugar de la recargarse
    localmente por las reciente lluvias de invierno, los pozos son alimentados por ancestrales
    aguas del Río Colorado de más de 10,000 años de antigüedad. Esta colección de paisajes de
    pozos arroja luz sobre su historia, compleja y entrelazada, y apunta hacia un futuro de
    conservación para estas vivificantes fuentes de agua.
                    </p>
                    </div>
                </div>

                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
