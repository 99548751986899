import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './chipAndMark.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ChipAndMarkImageGallery3 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/chip-and-mark"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Chip - Mark</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>
            <PageContainer>
                <div className="whyContainer">

                    <div className="whyDescriptionChipAndMark">

                        <h3><strong>A Desert Veil: Soft plants in a harsh landscape</strong></h3>
                        <br />
                        <p>
                            In his classic text, The Desert, author John C. Van Dyke described the desert as a “kingdom of sun-fire&quot;
        where ”every sunbeam falls as a shaft of flame.&quot; It’s a fascinating contrast that the desert’s very
        harshness has created lifeforms of such delicate beauty as diaphanous plants – plants so sparse in stems
        and foliage that one tends to look right through them. Scientifically, sparse stems and tiny leaves are
        adaptations to the excess of sunlight in deserts; large leaves can fatally overheat on summer days, and
        more total leaf surface area results in greater loss of limited water. Aesthetically, these plants are
        individually beautiful, and collectively contribute to the visual character of desert landscapes. Just as a
        veiled face encourages a closer look, these wispy plants invite attention to detail, and then help draw
        the visitor into the greater landscape.

                    </p>
                        <br />
                        <p>
                            In A Desert Veil: Soft Plants in a Harsh Landscape, Chip and Mark use lumen prints; a unique, and
        decidedly experimental process, to investigate diaphanous plant forms of the Sonoran Desert. The
        images are printed onto silk to create larger than life veils that emphasize the diaphanous qualities of
        some desert plants. Suspended in space, the veils allow museum visitors to experience the surrounding
        gallery installations, and each other, through the beautifully adaptive form of diaphanous plants.
                    </p>
                        <br />
                        <p>
                            Credit is due to landscape architect Iain Robertson (University of Washington) for recognizing this phenomenon that has been overlooked by most desert biologists and artists.
                    </p>
                    </div>
                    <div className="whyDescriptionChipAndMarkSpanish">
                        <h3><strong>El velo del desierto: plantas suaves en un paisaje áspero</strong></h3>
                        <br />
                        <p>
                            En su texto clásico, El desierto, el autor John C. Van Dyke describió el desierto como un &quot;reino del fuego
        solar&quot; donde &quot;cada rayo de sol cae como un haz de llamas&quot;. El que la dureza del desierto haya creado
        formas de vida de una belleza tan delicada como las de las plantas diáfanas, es un fascinante contraste
        ya que son plantas tan escasas en tallos y follaje que uno puede mirar directamente a través de ellas.
        Científicamente, los escasos tallos y pequeñas hojas son adaptaciones al exceso de luz solar en los
        desiertos. (Las hojas grandes se pueden sobrecalentar fatalmente en los días de verano y una mayor
        superficie total de la hoja produciría una mayor pérdida de la limitada agua disponible). Estéticamente,
        estas plantas son hermosas individualmente y contribuyen colectivamente al carácter visual de los
        paisajes desérticos. Así como una cara cubierta por un velo invita a una mirada más íntima, estas ralas
        plantas invitan a prestar atención al detalle, ayudando a atraer al visitante hacia el paisaje más allá.
                    </p>
                        <br />
                        <p>
                            En El velo del desierto: plantas suaves en un paisaje áspero, Chip y Mark usan impresiones de lumen, un
        proceso único y definitivamente experimental, para investigar las formas de plantas diáfanas del
        Desierto Sonorense. Las imágenes se imprimen en seda para crear velos más grandes que sus pares
        reales, los cuales enfatizan las cualidades diáfanas de algunas plantas del desierto. Suspendidos en el
        espacio, los velos permiten que los visitantes del museo experimenten las demás instalaciones de la
        galería, así como a los demás visitantes, a través de la hermosa y adaptable forma de las plantas
        diáfanas.
                    </p>
                    </div>
                </div>

                <Background
                    imgUrl={"/images/pages/background/unknownPlantB.jpg"}
                />
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
