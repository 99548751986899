import React from "react";
// import { Link } from "react-router-dom";
import "./NavBarLogo.css";
import HamburgerMenu from './HamburgerMenu'


export const NavBarLogo = () => {

    return (
        <div className="hamPosition">

            <div id="App">
                <HamburgerMenu />
            </div >
          </div>
    );
};
