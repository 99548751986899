import React from "react";
import "./ImagePage.css";
// import { NavBarLogo } from "../UI/NavBarLogo";
import Gallery from "react-photo-gallery";
import { ModalImage } from "./ModalImage";
import Lightbox from 'react-images'
// export class ImagePage extends React.Component {
//     state = {
//         modalImage: null,
//         modalImageIndex:null
//     };

//     handleImageClick = (e, obj) => {
//         console.log(obj);
//         const image = obj.photo;
//         this.setState({
//             modalImage: image
//         });
//     };

//     handleModalClick = (e) => {
//         this.setState({
//             modalImage: null
//         });
//     };

//     handleArrowLeftClick = (e, index) => {
//         const nextIndex = index - 1
//         this.setStaste({
//             modalImage: this.props.photos[nextIndex],
//             modalImageIndex: nextIndex
//         })
//     }

//     render() {
//         const { photos } = this.props;
//         const { modalImage, modalImageIndex } = this.state;

//         if (modalImage) {
//             return (
//                 <ModalImage
//                     image={modalImage}
//                     index={modalImageIndex}
//                     onClick={this.handleModalClick}
//                 />
//             )
//         } else {
//             return (
//                 <div className={"image-page-container"}>
//                     <NavBarLogo />
//                     <div className={"image-gallery"}>
//                         <Gallery
//                             photos={photos}
//                             onClick={(e, obj) => this.handleImageClick(e, obj)}
//                         />
//                     </div>
//                 </div>
//             );
//         }
//     }
// }



export class ImagePage extends React.Component {
    constructor() {
        super();
        this.state = { currentImage: 0 };
        this.closeLightbox = this.closeLightbox.bind(this);
        this.openLightbox = this.openLightbox.bind(this);
        this.gotoNext = this.gotoNext.bind(this);
        this.gotoPrevious = this.gotoPrevious.bind(this);
    }
    openLightbox(event, obj) {
        this.setState({
            currentImage: obj.index,
            lightboxIsOpen: true,
        });
    }
    closeLightbox() {
        this.setState({
            currentImage: 0,
            lightboxIsOpen: false,
        });
    }
    gotoPrevious() {
        this.setState({
            currentImage: this.state.currentImage - 1,
        });
    }
    gotoNext() {
        this.setState({
            currentImage: this.state.currentImage + 1,
        });
    }
    render() {
        const { photos } = this.props;

        return (

            <div>
                <div className={"image-page-container"}>

                    <Gallery photos={photos} onClick={this.openLightbox} />
                    <Lightbox images={photos}
                        onClose={this.closeLightbox}
                        onClickPrev={this.gotoPrevious}
                        onClickNext={this.gotoNext}
                        currentImage={this.state.currentImage}
                        isOpen={this.state.lightboxIsOpen}
                    />
                </div>
            </div>
        )
    }
}
