import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './kathleenAndMichael.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const KathleenAndMichaelImageGallery3 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/kathleen-and-michael"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Kathleen - Michael</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>

            <div className="whyContainer">
            <div className="KandMwhyDescription">
                <h3><strong>Living River: Flow of Life in the Santa Cruz</strong></h3>
                <br />

                <p>
                The Santa Cruz River has its headwaters in southeastern Arizona, flows south into Sonora, but
then turns sharply north, flowing through Tucson on its path toward the Gila River near Phoenix.
Historically, portions of the Santa Cruz River had perennial flow, allowing the city of Tucson to
develop in the dry Sonoran Desert. However, drought and overuse of water caused it to dry up
in the early 20th century. Thanks to treated wastewater, the Santa Cruz now has perennial flow
again; however that flow is vulnerable to competing demands for water and changes in human
values.


                    </p>
                <br />
                <p>
                Using scientific study and photographic based visual art, Michael and Kathleen investigated
perennial, temporary, and reborn waters of the Santa Cruz River basin near Tucson.
Specifically, they looked at how the reliability and source of water (treated wastewater vs
snowmelt) affects the look, feel, and aquatic biota of a given stream reach. The collaborators
considered how the human history of a given reach affects the flow and biota and what it means
to ‘re-create’ an ecosystem. Do the reborn waters of the Santa Cruz River need to look and feel
like the original ecosystem? What future can we envision for the river that gave birth to Tucson?
                    </p>

            </div>
            <div className="KandMwhyDescriptionSpanish">
                <h3><strong>Río vivo: flujo de vida en el Río Santa Cruz</strong></h3>
                <br />

                <p>
                El Río Santa Cruz nace en el sureste de Arizona, corre hacia el sur a Sonora, pero luego gira
bruscamente hacia el norte, fluyendo a través de Tucson en su camino hacia el río Gila, cerca
de Phoenix. Históricamente, partes del Río Santa Cruz sostuvieron un flujo perenne, lo que
permitió que la ciudad de Tucson se desarrollara en el árido Desierto Sonorense. Sin embargo,
la sequía y el uso excesivo del agua hicieron que el río se secara a principios del siglo XX.
Gracias a aguas residuales tratadas, el Río Santa Cruz ahora vuelve a tener un flujo perenne;
sin embargo, su flujo es vulnerable a las exigentes demandas de agua y cambios en el valor
que le atribuyen los humanos.


                    </p>
                <br />
                <p>
                Utilizando estudios científicos y artes visuales con una base en la fotografía, Michael y Kathleen
investigaron las aguas perennes, las temporales y aquellas que renacen en la cuenca del Río
Santa Cruz cerca de Tucson. Específicamente, observaron cómo la confiabilidad y la fuente del
agua (aguas residuales tratadas versus las de deshielo) afectan el aspecto, la sensación y la
biota acuática de una corriente específica. Los colaboradores consideraron cómo la historia
humana de una sección del río afecta el flujo y la biota, así como lo que significa &quot;recrear&quot; un
ecosistema. ¿Es que las aguas renacidas del Río Santa Cruz deben verse y sentirse como el
ecosistema original? ¿Qué futuro podemos prever para el río que dio a luz a la ciudad de
Tucson?

                    </p>
            </div>

            </div>
        <PageContainer>
            <Background
                imgUrl={"/images/pages/background/photogramSampleThreeTest.jpg"}
            />
                <NavBarLogo />
        </PageContainer>
        </React.Fragment >
    );
};
