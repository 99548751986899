import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './heatherAndTaylor.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const HeatherAndTaylorImageGallery3 = () => {
    return (
        <React.Fragment>
             <nav>
                <Link to={"/heather-and-taylor"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Heather - Taylor</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>

            <div className="whyContainer">
            <div className="whyDescriptionHeatherAndTaylor">
                    <h3><strong>Isle of Sauromalus</strong></h3>
                    <br/>
                    <p>
                    Punta la Cholla is a granitic outcropping along the narrow headland of La Cholla located in
Sonora, Mexico. Nestled within this rocky mountain there exists a rich diversity of life—one side
of the mountain pushes the limits of survival in a severe, arid environment; the other abounds
with the Gulf of California’s ultramarine shore. Counted among its inhabitants is the common
chuckwalla, <span className="italicPlain">Sauromalus ater;</span> a saxicolous (rock dwelling) lizard that occurs in disjunct, rocky
environments throughout the Sonoran and Mojave deserts. Like many of the residents of Punta
La Cholla, chuckwallas are naturally isolated here, but in the past had contact with neighboring
populations. Heather and Taylor investigated this small, sequestered population of chuckwallas
using DNA sequencing to better understand their origins and connectivity to other populations in
the surrounding landscape. They found that La Cholla chuckwallas have evolved on a geologic
scale; their story is told in the rocks and in their DNA.

                    </p>
                    <br/>
                    <p>
                    In a series of artists’ books, Heather and Taylor share a narrative about their experience
searching for these elusive creatures, and the increasing impact of human development that is
compounding their isolation. The last of these books is a lexicon that tells the story of the flora,
fauna, and past and present visitors of Punta La Cholla. It also serves as a kind of tribute to the
mountain, or ‘isle’ itself—describing its magnificent and idiosyncratic natural and cultural history.
Along the walls of the installation, the unique sequence of La Cholla chuckwalla DNA is
engraved upon sheets of mica, and vitrines display a sampling of natural and man-made objects
collected there. An ambient soundscape in the background helps to portray Punta La Cholla’s
desert-sea environs and some of the obstructions surrounding it.
                    </p>
                    </div>
                    <div className="whyDescriptionHeatherAndTaylorSpanish">
                    <h3><strong>Isla de Sauromalus</strong></h3>
                    <br/>
                    <p>
                    Punta la Cholla es un afloramiento granítico a lo largo del promontorio estrecho de La Cholla,
localizado en Sonora, México. Ubicado en esta montaña rocosa, existe una rica diversidad de
vida – un lado de la montaña empuja los límites de supervivencia en un entorno severo y árido;
el otro abunda en la costa ultramar del Golfo de California. Entre sus habitantes se encuentra el
chuckwalla común, Sauromalus ater; un lagarto saxicolous (morado de la roca) que se produce
en ambientes rocosos y aislados a lo largo de los desiertos de Sonora y Mojave. Como muchos
de los residentes de Punta La Cholla, las chuckwallas están naturalmente aisladas aquí, pero
en el pasado tenían contacto con las poblaciones vecinas. Heather y Taylor investigaron esta
pequeña población secuestrada de chuckwallas utilizando la secuenciación de ADN para
comprender mejor sus orígenes y conectividad con otras poblaciones en el paisaje circundante.
Descubrieron que las chuckwallas de La Cholla han evolucionado a escala geológica; su
historia la cuentan las rocas y su ADN.
                    </p>
                    <br/>
                    <p>
                    En una serie de libros de artista, Heather y Taylor comparten una narrativa sobre su
experiencia en la búsqueda de estas creaturas esquivas, y cómo el creciente impacto del
desarrollo humano agrava su aislamiento. El último de estos libros es un léxico que cuenta la
historia de la flora, la fauna y los visitantes pasados ​​y presentes de Punta La Cholla. También

sirve como una especie de homenaje a la montaña, o &quot;isla&quot; en sí; describe su magnífica e
idiosincrásica historia natural y cultural. A lo largo de las paredes de la instalación, la secuencia
única del ADN del chuckwalla de La Cholla se graba en láminas de mica, y las vitrinas
presentan una muestra de los objetos naturales y artificiales que se recolectan allí. Un paisaje
sonoro ambiental en el fondo ayuda a retratar los alrededores del mar del desierto de Punta La
Cholla y algunas de las obstrucciones que lo rodean.
                    </p>
                    </div>
                </div>
            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/chuckwallaTwoTest.jpg"}
                />
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
