import React from "react";
import { Background } from "../../components/UI/Background";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Title } from "../../components/project/Title";
import { PageContainer } from "../../components/UI/PageContainer";
import { ProjectContainer } from "../../components/project/ProjectContainer";
import { IconGrid } from "../../components/project/IconGrid";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const projectItems = [
    {
        icon: "user-friends",
        title: "People | Gente",
        link: "/tom-and-scott/people"
    },
    {
        icon: "light-bulb",
        title: "Concept | Lógica",
        link: "/tom-and-scott/image-gallery-3"
    },
    {
        icon: "microscope",
        title: "Science | Ciencia",
        link: "/tom-and-scott/image-gallery-2"
    },
    {
        icon: "paint-brush",
        title: "Art | Arte",
        link: "/tom-and-scott/image-gallery-1"
    },
    {
        icon: "globe",
        title: "Place | Lugar",
        link: "/tom-and-scott/image-gallery-4"
    }
];

const projectStyle = {
    color: "black",
    size: "4em"
};
export const TomAndScott = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/></h1>
                </Link>
            </nav>
            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/NewTomScottBackground1.jpg"}
                />
                <NavBarLogo />
                <ProjectContainer>
                    <Title color={"white"} title={"Tom - Scott"} />
                    <IconGrid
                        projectItems={projectItems}
                        projectStyle={projectStyle}
                    />
                </ProjectContainer>
            </PageContainer>
        </React.Fragment>
    );
};
