import React from "react";
import "./HomeGrid.css";
import HomeGridCard  from "./HomeGridCard";

export const HomeGrid = ({ homeGridItems }) => {
    return (
        <div className="home-grid">
            {homeGridItems.map((item, index) => {
                return (
                    <HomeGridCard title={item.title} imageUrl={item.imageUrl}  link={item.link} key={`home-grid-item-${index}`} />
                );
            })}
        </div>
    );
};
