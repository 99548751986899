import React from "react";
import { IconCard } from "./IconCard";
import './IconCard.css'

const style = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
};

export const IconGrid = ({ children, projectItems, projectStyle }) => {
    return (
        <div className="icons" style={style}>
            {projectItems.map((projectItem, index) => {
                return (
                    <IconCard 
                        key={`icon-card-${index}`}
                        icon={projectItem.icon}
                        link={projectItem.link}
                        title={projectItem.title}
                        color={projectStyle.color}
                        size={projectStyle.size}
                    />
                );
            })}
        </div>
    );
};
