import React from "react";
import { ImagePage } from "../../components/imageGallery/ImagePage";
import * as images from '../../config/images';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const photos = images[13];

export const KathleenAndMichaelImageGallery4 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/kathleen-and-michael"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Kathleen - Michael</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Place | Lugar</h2>

            <ImagePage photos={photos} />

        </React.Fragment>
    );
};