import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import './mariaAndEric.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const MariaAndEricImageGallery3 = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/maria-and-eric"}>
                    <h1><FontAwesomeIcon icon="arrow-left"/> Maria - Eric</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">Concept | Lógica</h2>

            <div className="whyContainer">
                <div className="whyDescriptionMariaAndEric">
                    <h3><strong>Bycatch</strong></h3>
                    <br />
                    <p>
                    In Bycatch, Eric Magrane and Maria Johnson present an exploration of the shrimp trawling
fishery in Mexico’s Gulf of California. Every night from September through March, hundreds of
boats traverse the water dragging hundred-foot long nets across the seafloor after a quarry of
shrimp. About 85% of the total weight captured is not shrimp and is made up of over 200 other
species; this is called “bycatch.”
                    </p>
                    <br />
                    <p>
                    Combining video, installation, drawings, and poetry based on overnight field research aboard
shrimp trawlers, Bycatch gives the viewer a taste of what it feels like to be knee deep in this
overwhelming pile of bycatch on the deck of a boat. The shrimp caught in the fishery is destined
for international markets, primarily to consumers in the U.S. The exhibit makes a visceral
connection between this fishing practice and individual food choices and points to the
intertwining of food systems, ecology, and economics.

                    </p>
                    <br />
                    <p>While the human dimensions of the shrimp fishery are complex, in this exhibit Eric and Maria
have focused primarily on the non-human bodies affected by the practice. They introduce you to
some specific individuals—such as a Shame-faced Crab, Pacific Snake Eel, and Sonora
Scorpionfish—caught in the nets. Bycatch sheds some light on a key conservation issue in
the Sonoran Desert’s sea, the Gulf of California.
</p>
                </div>
                <div className="whyDescriptionMariaAndEricSpanish">
                    <h3><strong>Bycatch</strong></h3>
                    <br />
                    <p>
                    En Bycatch, Eric Magrane y Maria Johnson presentan una exploración de la pesquería de
arrastre de camarón en el Golfo de California de México. Cada noche de septiembre a marzo,
cientos de lanchas atraviesan el agua arrastrando redes de treinta metros de largo a través del
lecho marino persiguiendo a su presa. Alrededor del 85% del peso total capturado no es de
camarón, sino que está compuesto por más de 200 especies; a esto se le llama &quot;captura
incidental&quot;.
                    </p>
                    <br />
                    <p>
                    Mediante una mezcla de video, instalación y poesía basada en la investigación de campo
realizada durante la noche que los autores pasaron a bordo de los arrastreros camaroneros,
Bycatch le ofrece al espectador sentir cómo es encontrarse en la cubierta de un barco
sumergido en esta abrumadora masa de captura incidental. El camarón capturado en la
pesquería está destinado a los mercados internacionales, principalmente a los consumidores
en los Estados Unidos. La exhibición establece una conexión visceral entre esta práctica de
pesca y nuestras elecciones personales de alimentación, y apunta a la interrelación que existe
entre los sistemas alimenticios, la ecología y la economía.

                    </p>
                    <br />
                    <p>Si bien las dimensiones humanas de la pesca de camarón son complejas, en esta exhibición,
Eric y Maria se enfocaron principalmente en los cuerpos no humanos afectados por la práctica.
Los autores presentan al espectador algunos de los específicos individuos afectados, como un
“cangrejo con cara de vergüenza”, el “anguila serpiente del pacífico” y “el pez escorpión

sonorense”, atrapados en las redes. Bycatch arroja luz sobre un problema clave de
conservación en el mar del Desierto Sonorense, el Golfo de California.</p>

                </div>
            </div>
            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/NewMariaEricBackground.jpg"}
                />
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
