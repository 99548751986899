import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import '../pages.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const ChipAndMarkPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/chip-and-mark"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Chip - Mark</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/unknownPlantB.jpg"}
                />
                <div className="whoContainer">
                    <div className="personOne">
                        <img src="/images/pages/who/candm/hedgcockChip.jpg" alt="chipandmark" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">   Charles (Chip) Hedgcock </span>is a black and white fine art photographer as well as a
professional photographer in life sciences and medicine, working most recently in the
Department of Entomology at the University of Arizona. Chip is a Registered Biological
Photographer (RBP) and a Fellow of the BioCommunications Association. He is primary
photographer for the GreaterGood.org Madrean Discovery Expeditions (MDE) and
travels to remote mountain ranges in Southern Arizona, Northern Sonora and
Chihuahua Mexico to document the habitats and diversity of life found there.

                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">  Charles (Chip) Hedgcock </span>es un fotógrafo de bellas artes en blanco y negro, así como un
fotógrafo profesional de ciencias biológicas y medicina, que trabajó recientemente en el
Departamento de Entomología de la Universidad de Arizona. Chip es un Fotógrafo
Biológico Registrado (RBP) y miembro de la BioCommunications Association. Es
fotógrafo principal de Madrean Discovery Expeditions (MDE) de GreaterGood.org y viaja
a cadenas montañosas remotas en el sur de Arizona, el norte de Sonora y Chihuahua,
México para documentar los hábitats y la diversidad de la vida que se encuentra allí.
                            </p>
                    </div>
                    <div className="personTwo">
                        <img src="/images/pages/who/candm/markDimmitt.jpg" alt="chipandmark" />
                    </div>

                <div className="bioText">
                    <br />
                    <p>
                    <span className="boldText">   Mark A. Dimmitt </span>is a botanist and vertebrate biologist. Mark worked at the Arizona-
Sonora Desert Museum in Tucson from 1979 to 2011, first as Curator of Botany, and
then as Director of Natural History. He is the author of more than 50 scientific and
popular publications about ecology and horticulture, and is a Fellow of the Cactus and
Succulent Society of America. Mark’s ongoing vocation is as a plant breeder and he has
introduced more than 50 cultivars.
                            </p>
                    <br />
                    <p className="spanishTextBio">
                    <span className="boldText">     Mark A. Dimmitt </span> es un botánico y biólogo de vertebrados. Mark trabajó en el Arizona-
Sonora Desert Museum en Tucson desde 1979 hasta 2011, primero como Curador de
Botánica y luego como Director de Historia Natural. Es autor de más de 50 publicaciones
científicas y populares sobre la ecología y horticultura, y es miembro de la Sociedad de
Cactus y Suculentas de América. La vocación de Mark es como obtentor y ha introducido
más de 50 cultivares.
                            </p>
                </div>
                </div>

                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
