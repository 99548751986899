import React from "react";
import { Background } from "../../components/UI/Background";
import { PageContainer } from "../../components/UI/PageContainer";
import { NavBarLogo } from "../../components/UI/NavBarLogo";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const TomAndScottPeople = () => {
    return (
        <React.Fragment>
            <nav>
                <Link to={"/tom-and-scott"}>
                    <h1><FontAwesomeIcon icon="arrow-left" /> Tom - Scott</h1>
                </Link>
            </nav>
            <h2 className="pageNavigation">People | Gente</h2>

            <PageContainer>
                <Background
                    imgUrl={"/images/pages/background/NewTomScottBackground1.jpg"}
                />
                <div className="whoContainer">
                    <div className="personOne">
                        <img src="/images/pages/who/tands/scottBennet.jpg" alt="tom and scott" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">      Scott E.K. Bennett</span> is a geologist, tectonicist, and earthquake scientist with the U.S.
Geological Society (USGS) in Menlo Park, California. His current research is focused on
geologic mapping, structural geology, neotectonics, and geomorphology of the Pacific
Northwest region and desert Southwest Regions of the United States. Scott has been
involved in numerous interdisciplinary, collaborative research projects in the Cascadia
subduction zone of the Pacific Northwest, on both margins of the Gulf of California
oblique rift, along the San Andreas fault system, and in the Rio Grande Rift.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">    Scott E.K. Bennett</span> es geólogo, tectonista y científico de terremotos en la Sociedad
Geológica de los Estados Unidos (USGS) en Menlo Park, California. Su investigación
actual se centra en el mapeo geológico, la geología estructural, la neotectónica y la
geomorfología de las regiones del Noroeste del Pacífico y Suroeste de los Estados
Unidos. Scott ha participado en numerosos proyectos de investigación interdisciplinarios
y colaborativos en la zona de subducción de Cascadia del Pacífico Noroeste, en ambos
márgenes de la grieta oblicua del Golfo de California, a lo largo del sistema de fallas de
San Andrés y en la Grieta del Río Grande.
                            </p>
                    </div>
                    <div className="personTwo">
                        <img src="/images/pages/who/tands/tom.jpg" alt="tom and scott" />
                    </div>
                    <div className="bioText">
                        <br />
                        <p>
                        <span className="boldText">    Thomas (Tom) Baumgartner</span> is a designer and artist who works in a variety of digital and
mixed media, including oil painting. With more than 20 years experience in print design
and information technologies, Tom is the Creative Director and Senior Web Designer for
Ridgewood Associates in Tucson. He also currently directs Wee Gallery in Tucson. A life-
long nature lover, his art focuses on animal metaphors, the desert landscape, automatic
writing and manmade infrastructure in the natural environment.
                            </p>
                        <br />
                        <p className="spanishTextBio">
                        <span className="boldText">     Thomas (Tom) Baumgartner </span>es diseñador y artista que trabaja con una variedad de
medios digitales y mixtos, incluyendo la pintura al óleo. Con más de 20 años de
experiencia en diseño de impresión y tecnologías de información, Tom es el Director
Creativo y Diseñador Web Señor de Ridgewood Associates en Tucson. Actualmente
también dirige la Wee Gallery en Tucson. Amante de la naturaleza durante toda su vida,
su arte se centra en las metáforas de los animales, el paisaje desértico, la escritura
automática y la infraestructura creada por el hombre en su entorno natural.
                            </p>
                    </div>
                </div>
                <NavBarLogo />
            </PageContainer>
        </React.Fragment>
    );
};
