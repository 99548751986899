import React from "react";
import { slide as Menu} from 'react-burger-menu'
import './hamburgerMenu.css'

export default props => {
    return (
        <Menu right width={300} noOverlay>
        {/* <br/> */}
        {/* <hr/> */}
  
        <a className="menu-item" href="/ben-and-ben">
          Benjamin - Ben
        </a>
  
        <a className="menu-item" href="/maria-and-eric">
          Maria - Eric
        </a>
  
        <a className="menu-item" href="/chip-and-mark">
          Chip - Mark
        </a>
  
        <a className="menu-item" href="/heather-and-taylor">
          Heather - Taylor
        </a>
  
        <a className="menu-item" href="/kathleen-and-michael">
          Kathleen - Michael
        </a>
        <a className="menu-item" href="/tom-and-scott">
          Tom - Scott
        </a>
      </Menu>

    );
};